<template>
  <div v-if="record" class="record-view">
    <h1>Confirmation Details</h1>
    <div class="record-content">
      <div class="before-after">
        <div class="before">
          <h2>Before</h2>
          <img :src="record.beforeImageUrl" :alt="record.beforeDescription" class="record-image">
          <p><strong>Description:</strong> {{ record.beforeDescription }}</p>
        </div>
        <div class="after">
          <h2>After</h2>
          <img :src="record.afterImageUrl" :alt="record.afterDescription" class="record-image">
          <p><strong>Description:</strong> {{ record.afterDescription }}</p>
        </div>
      </div>
      <div class="record-info">
        <p><strong>Location:</strong> {{ record.location }}</p>
        <p><strong>Time Requested:</strong> {{ formatDate(record.timeRequested) }}</p>
        <p><strong>Time Completed:</strong> {{ formatDate(record.timeCompleted) }}</p>
        <p><strong>Donation Amount:</strong> ${{ record.donationAmount.toFixed(2) }}</p>
        <p><strong>Status:</strong> 
          <span class="status status-completed">Completed</span>
        </p>
      </div>
    </div>
    <div class="feedback">
      <h2>Feedback</h2>
      <p>{{ record.feedback }}</p>
    </div>
  </div>
  <div v-else-if="error" class="error-message">
    {{ error }}
  </div>
  <div v-else class="loading">
    Loading...
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ConfirmView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      record: null,
      error: null
    };
  },
  methods: {
    async fetchRecord() {
      try {
        const response = await axios.get(`/api/confirmations/${this.id}`);
        this.record = response.data;
      } catch (error) {
        console.error('Error fetching confirmation record:', error);
        this.error = 'Failed to load confirmation record. Please try again later.';
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    }
  },
  mounted() {
    this.fetchRecord();
  }
};
</script>

<style scoped>
.record-view {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.record-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.before-after {
  display: flex;
  gap: 20px;
}

.before, .after {
  flex: 1;
}

.record-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.record-info {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.status-completed {
  background-color: #4CAF50;
  color: white;
}

.feedback {
  margin-top: 20px;
  background-color: #e9e9e9;
  padding: 20px;
  border-radius: 5px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.loading {
  text-align: center;
  margin-top: 20px;
}
</style>
<template>
    <form @submit.prevent="submitForm">
      <div>
        <label for="name">Name:</label>
        <input id="name" v-model="user.name" required>
      </div>
      <div>
        <label for="email">Email:</label>
        <input id="email" v-model="user.email" type="email" required>
      </div>
      <div>
        <label for="phone">Phone:</label>
        <input id="phone" v-model="user.phone" type="tel" required>
      </div>
      <button type="submit">Subscribe</button>
    </form>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'SignUp',
    data() {
      return {
        user: {
          name: '',
          email: '',
          phone: '',
        },
      };
    },
    methods: {
      async submitForm() {
        try {
          const response = await axios.post('https://api.brevo.com/v3/contacts', {
            email: this.user.email,
            attributes: {
              FIRSTNAME: this.user.name.split(' ')[0],
              LASTNAME: this.user.name.split(' ').slice(1).join(' '),
              SMS: this.user.phone,
            },
            listIds: [123455], // Replace with your actual Brevo list ID
          }, {
            headers: {
              'api-key': 'YOUR_API_KEY', // Replace with your actual Brevo API key
              'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 201) {
            alert('Successfully subscribed!');
            // Reset form
            this.user = {
              name: '',
              email: '',
              phone: '',
            };
          }
        } catch (error) {
          console.error('Error subscribing user:', error);
          alert('An error occurred. Please try again.');
        }
      },
    },
  }
  </script>
  

  
  <style scoped>
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 300px;
    margin: 0 auto;
  }
  
  label {
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
  }
  
  button {
    padding: 0.5rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  </style>
<template>
  <div>

    <div id="map" class="map-container" ref="mapRef"></div>
    <!-- 
    <div>
      <input v-model="address" placeholder="Enter an address" @keyup.enter="searchAddress">
      <button @click="searchAddress">Search</button>
    </div>
    -->
  </div>
</template>

<script>
/* global google */

export default {
  name: 'MapApi',
  props: {
    geofence: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      map: null,
      marker: null,
      inFence: false,
      hasLocation: false,
      address: '',
      geocoder: null,
      googleMapsLoaded: false
    }
  },
  async mounted() {
    try {
      await this.loadGoogleMapsScript();
      console.log('Google Maps script loaded');
    } catch (error) {
      console.error('Failed to load Google Maps:', error);
    }
  },
  methods: {
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          this.googleMapsLoaded = true;
          resolve();
          return;
        }

        const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
        console.log('API Key:', apiKey); // For debugging

        if (!apiKey) {
          console.error('Google Maps API key is missing');
          reject(new Error('Google Maps API key is missing'));
          return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          console.log('Script loaded successfully');
          this.googleMapsLoaded = true;
          resolve();
        };

        script.onerror = (error) => {
          console.error('Script loading error:', error);
          reject(error);
        };

        document.head.appendChild(script);
      });
    },
    initMap(lat = 0, lng = 0) {
      console.log('Initializing map with:', { lat, lng });
      
      if (!window.google || !window.google.maps) {
        console.error('Google Maps not loaded yet');
        return;
      }

      try {
        const mapOptions = {
          zoom: 15,
          center: { lat, lng }
        };

        this.map = new google.maps.Map(this.$refs.mapRef, mapOptions);
        this.geocoder = new google.maps.Geocoder();

        if (this.marker) {
          this.marker.setMap(null);
        }
        
        this.marker = new google.maps.Marker({
          position: { lat, lng },
          map: this.map,
          title: 'Your Location'
        });

        if (this.geofence && this.geofence.points) {
          const geofencePath = this.geofence.points.map(point => ({
            lat: point.lat,
            lng: point.lng
          }));
          
          new google.maps.Polygon({
            paths: geofencePath,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: this.map
          });
        }
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            console.log('Got current position:', pos);

            //this.initMap(pos.lat, pos.lng);
          },
          (error) => {
            console.error('Geolocation error:', error);
            alert('Error: The Geolocation service failed.');
            // Initialize map with default location if geolocation fails
            this.initMap(34.0522, -118.2437); // Default to Los Angeles
          }
        );
      } else {
        console.error('Geolocation not supported');
        alert('Error: Your browser doesn\'t support geolocation.');
        // Initialize map with default location if geolocation is not supported
        this.initMap(34.0522, -118.2437); // Default to Los Angeles
      }
    },
    searchAddress() {
      if (!this.geocoder) {
        console.error('Geocoder not initialized');
        return;
      }
      
      this.geocoder.geocode({ address: this.address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          this.initMap(location.lat(), location.lng());
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  }
};
</script>

<style scoped>

.map-container {
  width: 100%;
  height: calc(100vh - clamp(10px, 10vw, 100px));
}

input, button {
  margin: 5px;
  padding: 5px;
}
</style>
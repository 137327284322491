<template>
  <div class="background"> 
    <div class="container">
      <div class="title">
          <img class="animate__animated animate__tada" src="@/assets/pitch-in-title.svg" alt="Pitch In" />
          <img class="animate__animated animate__tada" src="@/assets/trash-cash.svg" alt="Donate Cash, Pick up Trash!" />
      </div>
    <router-view/>
    </div><!-- end container  -->
    <div class="trashy animate__animated animate__bounceIn animate__delay-1s"></div>
  </div><!-- end background  -->

</template>

<script>


export default {
  name: 'App'
}
</script>

<style>

html, body {
 margin: 0;
 padding: 0;
}

.background {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    background-image: url(@/assets/bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    border: clamp(10px, 10vw, 100px) solid var(--green);
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
  }

  .title {
    position: absolute;
    left:0;
    right:0;
    max-width: 500px;
    margin: 0 auto;
    transform: rotate(-6deg) translateY(-40%);
    z-index:10;
  }

  .trashy {
    position: absolute;
    bottom: -25px;
    left: 0;
    width:50%;
    max-width: 500px;
    aspect-ratio: 1/1;
    background-image: url(@/assets/pitch-in.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }


img {
  width: 100%;
  height: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px auto;
}
</style>

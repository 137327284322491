<template>
  <div v-if="!locationChecked">
    <div class="location-status" v-if="locationChecked">
      <p :class="{ 'in-zone': inServiceArea, 'out-zone': !inServiceArea }">
        {{ locationMessage }}
      </p>
    </div>
    <SignUp v-if="!inServiceArea" />

    <MapApi 
      :geofence="formattedGeofence" 
      @location-checked="handleLocationCheck"
    />
    <CameraAccess v-if="inServiceArea" />
  </div>
</template>

<script>
import MapApi from '@/components/MapApi.vue';
import SignUp from '@/components/SignUp.vue';
import CameraAccess from '@/components/CameraAccess.vue';

export default {
  components: {
    MapApi,
    SignUp,
    CameraAccess
  },
  data() {
    return {
      geofenceData: {
        type: 'Polygon',
        coordinates: [
          [
            [-122.48, 37.78],
            [-122.38, 37.78],
            [-122.38, 37.68],
            [-122.48, 37.68],
            [-122.48, 37.78]
          ]
        ]
      },
      inServiceArea: false,
      locationChecked: false
    }
  },
  computed: {
    formattedGeofence() {
      // Convert GeoJSON format to the format expected by MapApi
      return {
        points: this.geofenceData.coordinates[0].map(coord => ({
          lng: coord[0],
          lat: coord[1]
        }))
      }
    },
    locationMessage() {
      if (!this.locationChecked) return '';
      return this.inServiceArea 
        ? 'You are in our service area! Please proceed with your request.'
        : 'Sorry, you are outside our service area. We currently a pilot program in West LA.';
    }
  },
  methods: {
    handleLocationCheck(result) {
      this.inServiceArea = result.inFence;
      this.locationChecked = true;
      
      if (!this.inServiceArea) {
        // Optionally show a modal or alert for out-of-service-area users
        this.$emit('out-of-service', {
          message: 'Location is outside service area',
          coordinates: result.location
        });
      }
    }
  }
}
</script>

<style scoped>
.location-status {
  margin: 20px auto;
  padding: 15px;
  max-width: 600px;
  border-radius: 8px;
  text-align: center;
}

.location-status p {
  margin: 0;
  font-size: 1.1em;
  font-weight: 500;
}

.in-zone {
  background-color: #e8f5e9;
  color: #2e7d32;
  border: 1px solid #a5d6a7;
}

.out-zone {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ef9a9a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .location-status {
    margin: 10px;
    padding: 10px;
  }
}
</style>
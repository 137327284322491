<template>
  <div class="home">
    <AppGuide />
  </div>
</template>

<script>
import AppGuide from '@/components/AppGuide.vue';

export default {
  name: 'HomeView',
  components: {
    AppGuide
  }
};
</script>
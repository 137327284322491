<template>
  <div class="camera-container">
    <h2>Camera Access</h2>
    
    <!-- Video stream display -->
    <video ref="videoElement" v-show="!capturedImage" autoplay playsinline></video>
    
    <!-- Captured image display -->
    <div v-if="capturedImage" class="captured-image">
      <img :src="capturedImage" alt="Captured photo" />
      <button @click="retakePhoto" class="camera-btn">Retake Photo</button>
    </div>
    
    <!-- Camera controls -->
    <div class="camera-controls" v-if="!capturedImage">
      <button @click="startCamera" v-if="!cameraActive" class="camera-btn">
        Start Camera
      </button>
      <button @click="capturePhoto" v-if="cameraActive" class="camera-btn">
        Take Photo
      </button>
    </div>

    <!-- Error message -->
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CameraAccess',
  data() {
    return {
      stream: null,
      cameraActive: false,
      capturedImage: null,
      error: null
    }
  },
  methods: {
    async startCamera() {
      try {
        this.error = null;
        const constraints = {
          video: {
            facingMode: 'environment', // Use back camera if available
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }
        };

        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        const video = this.$refs.videoElement;
        video.srcObject = this.stream;
        this.cameraActive = true;
      } catch (err) {
        console.error('Error accessing camera:', err);
        this.error = 'Unable to access camera. Please ensure you have granted camera permissions.';
      }
    },

    async capturePhoto() {
      try {
        const video = this.$refs.videoElement;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        
        // Convert canvas to data URL
        this.capturedImage = canvas.toDataURL('image/jpeg');
        
        // Stop camera stream
        this.stopCamera();
        
        // Emit the captured image to parent component
        this.$emit('photo-captured', this.capturedImage);
      } catch (err) {
        console.error('Error capturing photo:', err);
        this.error = 'Failed to capture photo. Please try again.';
      }
    },

    stopCamera() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop());
        this.stream = null;
        this.cameraActive = false;
      }
    },

    retakePhoto() {
      this.capturedImage = null;
      this.startCamera();
    }
  },

  unmounted() {
    this.stopCamera();
  }
}
</script>

<style scoped>
.camera-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

video {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  margin: 20px 0;
  background-color: #f0f0f0;
}

.captured-image {
  margin: 20px 0;
}

.captured-image img {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
}

.camera-controls {
  margin: 20px 0;
}

.camera-btn {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.camera-btn:hover {
  background-color: #45a049;
}

.error-message {
  color: #ff0000;
  margin: 10px 0;
  padding: 10px;
  background-color: #ffebee;
  border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .camera-container {
    padding: 10px;
  }
  
  .camera-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}
</style>
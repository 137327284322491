<template>
  <div v-if="record" class="record-view">
    <h1>Request Details</h1>
    <div class="record-content">
      <img :src="record.imageUrl" :alt="record.description" class="record-image">
      <div class="record-info">
        <p><strong>Location:</strong> {{ record.location }}</p>
        <p><strong>Time Requested:</strong> {{ formatDate(record.timeRequested) }}</p>
        <p><strong>Description:</strong> {{ record.description }}</p>
        <p><strong>Donation Amount:</strong> ${{ record.donationAmount.toFixed(2) }}</p>
        <p><strong>Status:</strong> 
          <span :class="['status', statusClass]">{{ record.status }}</span>
        </p>
      </div>
    </div>
    <div v-if="record.status === 'needs_additional_donation'" class="additional-donation">
      <p>Additional donation required to process this request.</p>
      <button @click="handleAdditionalDonation">Make Additional Donation</button>
    </div>
  </div>
  <div v-else-if="error" class="error-message">
    {{ error }}
  </div>
  <div v-else class="loading">
    Loading...
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RecordView',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      record: null,
      error: null
    };
  },
  computed: {
    statusClass() {
      const statusMap = {
        accepted: 'status-accepted',
        declined: 'status-declined',
        needs_additional_donation: 'status-needs-donation'
      };
      return statusMap[this.record.status] || '';
    }
  },
  methods: {
    async fetchRecord() {
      try {
        const response = await axios.get(`/api/records/${this.id}`);
        this.record = response.data;
      } catch (error) {
        console.error('Error fetching record:', error);
        this.error = 'Failed to load record. Please try again later.';
      }
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },
    handleAdditionalDonation() {
      // Implement additional donation logic here
      console.log('Handle additional donation');
    }
  },
  mounted() {
    this.fetchRecord();
  }
};
</script>

<style scoped>
.record-view {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.record-content {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.record-image {
  width: 300px;
  height: auto;
  object-fit: cover;
}

.record-info {
  flex: 1;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.status-accepted {
  background-color: #4CAF50;
  color: white;
}

.status-declined {
  background-color: #F44336;
  color: white;
}

.status-needs-donation {
  background-color: #FFC107;
  color: black;
}

.additional-donation {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.loading {
  text-align: center;
  margin-top: 20px;
}
</style>
<template>

   
      <div class="app-guide">


        <div class="frame-container">
          
        <div class="frame" v-for="(frame, index) in frames" :key="index" :class="{ active: currentFrame === index }">
          <h2>{{ frame.title }}</h2>
          <p>{{ frame.description }}</p>
          <img v-if="frame.image" :src="frame.image" :alt="frame.title">
        </div>
        
      </div>

      <button @click="goToRequest" class="cta-button">Pitch In</button>
      
    </div>


  </template>
  
  <script>
  export default {
    name: 'AppGuide',
    data() {
      return {
        currentFrame: 0,
        frames: [
          { title: "Step 1", description: "Take a picture or describe the Problem", image: "/images/take-picture.png" },
          { title: "Step 2", description: "Drop a pin", image: "/images/drop-pin.png" },
          { title: "Step 3", description: "Make a donation", image: "/images/make-donation.png" },
          { title: "We've Got It From Here", description: "Our Sanitation experts pick up the trash and send you a confirmation", image: "/images/confirmation.png" }
        ]
      };
    },
    mounted() {
      this.startAnimation();
    },
    methods: {
      startAnimation() {
        setInterval(() => {
          this.currentFrame = (this.currentFrame + 1) % this.frames.length;
        }, 5000); // Change frame every 5 seconds
      },
      goToRequest() {
        this.$router.push({ name: 'Request' });
      }
    }
  };
  </script>
  
  <style scoped>


  .app-guide {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .frame-container {
    position: relative;
    height: 400px;
    overflow: hidden;
  }
  
  .frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .frame.active {
    opacity: 1;
  }
  
  .frame h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .frame p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .frame img {
    max-width: 200px;
    max-height: 200px;
  }
  
  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: #ddd;
    margin-top: 20px;
  }
  
  .progress {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.5s ease-in-out;
  }
  
  .cta-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #45a049;
  }
  </style>
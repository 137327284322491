import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RequestView from '../views/RequestView.vue'
import PaymentView from '../views/PaymentView.vue'
import OpenView from '../views/OpenView.vue'
import ConfirmView from '../views/ConfirmView.vue'

// Define routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/request',
    name: 'Request',
    component: RequestView
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentView
  },
  {
    path: '/open/:id',
    name: 'Open',
    component: OpenView,
    props: true
  },
  {
    path: '/confirm/:id',
    name: 'Confirm',
    component: ConfirmView,
    props: true
  }
]

// Create router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router